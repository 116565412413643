import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CounterState {
  loading: boolean;
  error?: Error;
}

const initialState: CounterState = {
  loading: false,
  error: undefined,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    fetchStarted: state => {
      state.loading = true;
    },
    fetchFulfilled: state => {
      state.loading = false;
    },
    fetchFailed: (state, action: PayloadAction<Error>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchStarted, fetchFulfilled, fetchFailed } =
  commonSlice.actions;

export default commonSlice.reducer;
