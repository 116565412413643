import React from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { AppDispatch, RootState } from "../../redux/store";
import { fetchStarted } from "../../redux/slices/commonSlice";

import logo from "../../logo.svg";
import styles from "./index.module.css";

interface IHomeProps extends RouteComponentProps {
  loading: boolean;
  fetchStarted: () => void;
}

class Home extends React.Component<IHomeProps> {
  render() {
    return (
      <div className={styles.container}>
        <header className={styles.header}>
          <img src={logo} className={styles.logo} alt="logo" />
          <p>Website is under the construction!</p>
        </header>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.common.loading,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchStarted: bindActionCreators(fetchStarted, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
